<template>
  <v-container
    id="member-resume"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="loader"
      persistent
      width="500"
    >
      <div class="text-center transparent py-5">
        <v-progress-circular
          :size="50"
          :width="7"
          color="indigo"
          indeterminate
        />
      </div>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="3"
      >
        <v-img
          :width="300"
          src="@/assets/logo.png"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="6"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Resumen de la información
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Por favor verifica tus datos.
            </div>
          </template>

          <v-subheader>Información del grupo</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Grupo seleccionado: </span>
              <span class="font-weight-light">{{ suscripcion.grupo.grupo }}</span>
            </v-col>
          </v-row>

          <v-subheader>Información personal</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Nombre: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.nombre }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Apellidos: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.apellido }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Email: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.mail }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Teléfono: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.telefono }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Especialidad profesional: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.especialidad }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Invitado por: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.invito }}</span>
            </v-col>
          </v-row>

          <v-subheader>Información de facturación</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Razón social/Nombre fiscal: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.razon }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">NIF/CIF: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.cif }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Dirección fiscal: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.domicilio }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Población: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.poblacion }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Provincia: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.provincia }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Código postal: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.postal }}</span>
            </v-col>
          </v-row>

          <v-subheader>Información de networking</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">¿Ha sido miembro de BNI anteriormente?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.miembroBNI }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">En caso afirmativo, ¿cuándo y en qué grupo?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.grupoBNIAnt }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.miembroOrgQ }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">En caso afirmativo, ¿a cuál?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.miembroOrgA }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-btn
                color="primary"
                :disabled="bloquearModificar"
                @click="deleteSuscripcion()"
              >
                MODIFICAR
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        sm="10"
        md="4"
        lg="3"
      >
        <v-row
          v-for="(elemento) in grupos"
          :key="elemento.id"
        >
          <base-material-card
            color="primary"
          >
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">
                Realización de pago
              </div>
            </template>
            <v-card-text
              v-if="revealForm"
              class="text-center"
            >
              <div class="container">
                <v-row align="center">
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-card-text>
                      <v-row
                        justify="center"
                        align="center"
                      >
                        <v-col
                          cols="7"
                          class="text-center font-weight-regular"
                        >
                          Membresía {{ elemento.grupo }}
                        </v-col>
                        <v-col
                          cols="5"
                          class="text-center font-weight-regular"
                        >
                          {{ elemento.valor | toBaseAmount }}
                        </v-col>
                      </v-row>
                      <v-row
                        justify="center"
                        align="center"
                      >
                        <v-col
                          cols="7"
                          class="text-center font-weight-regular"
                        >
                          IVA (21%)
                        </v-col>
                        <v-col
                          cols="5"
                          class="text-center font-weight-regular"
                        >
                          {{ elemento.valor | toTaxValue }}
                        </v-col>
                      </v-row>
                      <v-row
                        justify="center"
                        align="center"
                      >
                        <v-col
                          cols="7"
                          class="text-center font-weight-regular font-weight-black"
                        >
                          TOTAL
                        </v-col>
                        <v-col
                          cols="5"
                          class="text-center font-weight-regular font-weight-black"
                        >
                          {{ elemento.valor | toTotal }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <!-- Medios de pago -->
                    <div class="text-center">
                      <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="GoToPaymentMethod(elemento, 'card')"
                        >
                          Tarjeta Débito/Crédito
                        </v-btn>
                      </div>
                      <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="GoToPaymentMethod(elemento, 'transfer')"
                        >
                          Transferencia bancaria
                        </v-btn>
                      </div>
                      <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="GoToPaymentMethod(elemento, 'paypal')"
                        >
                          PayPal
                        </v-btn>
                      </div>
                      <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="GoToPaymentMethod(elemento, 'installments')"
                        >
                          Pago en 3 cuotas sin intereses
                        </v-btn>
                      </div>
                      <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="GoToPaymentMethod(elemento, 'sepa')"
                        >
                          Domiciliación anual SEPA
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-expand-transition>
              <v-card
                v-if="reveal"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 0%;"
              >
                <v-card-title>
                  <div class="green darken-2 text-center rounded mx-auto mb-4 pa-4 d-block">
                    <v-icon
                      large
                      color="white"
                    >
                      mdi-check-circle
                    </v-icon>
                    <span class="text-h3 font-weight-bold white--text text-needs-wrapping"> Pago realizado con éxito</span>
                  </div>
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <p>Muchas gracias, hemos recibido tu pago correctamente, recibirás en tu correo una notificación con los datos de la transacción realizada.</p>
                    </v-col>
                  </v-row>
                  <v-subheader>Detalles del pago realizado</v-subheader>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span class="font-weight-regular">Número de transacción: </span>
                      <span class="font-weight-light">{{ transaccion.transId }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span class="font-weight-regular">Referencia pedido: </span>
                      <span class="font-weight-light">{{ transaccion.orderId }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    to="/member/register"
                  >
                    SALIR
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </base-material-card>
        </v-row>
      </v-col>
    </v-row>

    <!-- Formulario que se envía a la ventana externa de pago -->
    <form
      ref="paymentForm"
      :action="formularioLyra.form.action"
      :method="formularioLyra.form.method"
      accept-charset="UTF-8"
      :enctype="formularioLyra.form.enctype"
    >
      <input
        v-for="(value, name) in formularioLyra.fields"
        :key="name"
        type="hidden"
        :name="name"
        :value="value"
      >
    </form>
    <!-- // Formulario que se envía a la ventana externa de pago -->
  </v-container>
</template>

<script>
  import SuscriptionApi from '@/services/api/Suscripciones'

  export default {
    filters: {
      toBaseAmount (value) {
        if (undefined !== value) {
          value = value.replace('.', '')
          value = value.replace(',', '.')
          let base = parseFloat(value)
          base = (base * 100) / 121
          const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
          })
          return formatter.format(base)
        }
      },
      toTaxValue (value) {
        if (undefined !== value) {
          value = value.replace('.', '')
          value = value.replace(',', '.')
          let tax = parseFloat(value)
          tax = tax = (tax * 21) / 121
          const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
          })
          return formatter.format(tax)
        }
      },
      toTotal (value) {
        if (undefined !== value) {
          value = value.replace('.', '')
          value = value.replace(',', '.')
          const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
          })
          return formatter.format(value)
        }
      },
    },
    data: () => ({
      panel: [0],
      grupos: [],
      suscripcion: {
        id: '',
        estado: '',
        fecha: '',
        grupo: [],
        miembro: {
          nombre: '',
          apellido: '',
          mail: '',
          telefono: '',
          especialidad: '',
          invito: '',
          razon: '',
          cif: '',
          domicilio: '',
          poblacion: '',
          provincia: '',
          postal: '',
        },
        comprobante: '',
      },
      formularioLyra: {
        fields: [],
        form: [],
        grupo: [],
      },
      reveal: false,
      revealForm: true,
      transaccion: {
        transId: '',
        orderId: '',
      },
      loader: false,
      snackbar: false,
      colorSnak: 'info',
      text: '',
      timeout: 3000,
      bloquearModificar: true,
      rutaPrevia: null,
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.rutaPrevia = from
      })
    },
    mounted () {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.location.reload()
        }
      }
      this.initialize()
    },
    methods: {
      fetchResume () {
        SuscriptionApi.getSuscripcionByToken(this.$route.params.token)
          .then(suscripcion => {
            this.suscripcion = suscripcion.suscripcion
            this.grupos = suscripcion.grupos

            if (parseInt(this.suscripcion.miembro.eliminado) === 1) {
              this.$router.push({ name: 'Member Register' })
            } else if (this.suscripcion.estado !== 'PAGADO') {
              this.bloquearModificar = false
            } else {
              this.reveal = true
              this.revealForm = false
              this.transaccion.transId = this.suscripcion.lyra_trans_id
              this.transaccion.orderId = this.suscripcion.token
            }
          })
          .catch(error => {
            this.revealForm = false
            console.log(error)
            this.text = 'Suscripción no encontrada. Verique si ha recibido un mail con un enlace más reciente'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      initialize () {
        this.fetchResume()
      },
      GoToPaymentMethod (group, method) {
        this.loader = true

        const formData = {
          suscripcion: this.suscripcion,
          grupo: group,
          metodo: method,
        }
        SuscriptionApi.generatePaymentData(formData)
          .then(response => {
            // Verificar el vads_trans_id que se genera
            // console.log(suscripcion.lyraForms[0].fields.vads_trans_id)
            this.formularioLyra = response.paymentForm

            /**
             * Esperar un momento, para asegurar que el formulario se rellena
             * con toda la información antes del submit.
             *
             * Con esta acción se redirige a la ventana de pago de Lyra
             */
            setTimeout(() => {
              this.loader = false
              this.$refs.paymentForm.submit()
            }, 1500)
          })
          .catch(error => {
            this.loader = false
            this.revealForm = false
            console.log(error)
            this.text = 'Ha ocurrido un error. Intente nuevamente o póganse en contacto con nosotros.'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      /* enviarDocumento (grupoId) {
        const archivo = this.suscripcion.comprobante
        if (!archivo || archivo == null) {
          this.text = 'Debes seleccionar un documento'
          this.colorSnak = 'info'
          this.snackbar = true
        } else {
          const formData = new FormData()
          formData.append('_file', this.suscripcion.comprobante)
          formData.append('suscripcion_id', this.suscripcion.id)
          formData.append('grupo_id', grupoId)
          formData.append('oficial', true)

          SuscriptionApi.uploadFile(formData)
            .then(response => {
              this.text = 'Documento cargado correctamente. Recibirá un correo de confirmación'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'El documento no puede ser cargado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      }, */
      deleteSuscripcion () {
        SuscriptionApi.deleteSuscripcion(this.suscripcion.id)
          .then(response => {
            this.$router.push({ name: 'Member Token Register', params: { token: this.suscripcion.miembro.token } })
            /* if (this.rutaPrevia.name === 'Member Renewal') {
              this.$router.push({ name: 'Member Renewal', params: { grupo: this.suscripcion.grupo, mail: this.suscripcion.miembro.mail } })
            } else {
              this.$router.push({ name: 'Member Token Register', params: { token: this.suscripcion.miembro.token } })
            } */
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .text-needs-wrapping {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
</style>
